<template>
  <div class="index">
    <v-row justify="center" class="my-15">
      <v-col cols="11" sm="9" md="6">
        <h1 class="text-shadow text-center mb-5 primary--text">{{ $t('indexTitle') }}</h1>
        <h3 class="text-shadow text-center mb-15 primary--text">{{ $t('indexSubtitle') }}</h3>
        <v-card light class="index-card card-wrap">
          <div class="text-center mb-5">{{ $t('indexDesc') }}</div>
          <div class="d-flex flex-column justify-center align-center mb-5">
            <h2 class="primary--text">{{ $t('investAmount') }}</h2>
            <h2 class="mb-3">{{ totalAmount.toLocaleString() }} USDT</h2>
            <h2 class="primary--text">{{ $t('gamePoolAmount') }}</h2>
            <h2 class="red--text mb-5">{{ roundAmount.toLocaleString() }} USDT</h2>
            <btn class="mb-3" :buttonText="'gamePool'" color="primary" :width="'100%'" @clickBtn="toGame()"></btn>
            <div class="bsc-egt-block d-flex flex-column justify-center align-center pa-3 can-click" @click="toSwap()">
              <img src="@/assets/img/icon-bscegt.png" width="40px">
              <div class="primary--text font-weight-bold">{{ $t('swapEGT') }}</div>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import btn from "@/components/btn.vue";
import Game from "@/plugins/game.js";
import Defi from "@/plugins/defi.js";
export default {
  name: "Home",
  data(){
    return {
      gameContract: null,
      defiContract: null,
      totalAmount: '--',
      roundAmount: '--'
    }
  },
  components:{
    btn
  },
  methods:{
    toGame(){
      if (this.$store.state.account){
        this.$router.push({name: 'Game'})
      }else{
        this.$toasted.error(this.$t('loginFirst'))
      }
    },
    toSwap(){
      if (this.$store.state.account){
        this.$router.push({name: 'Egt-swap', params: {lang: this.$store.state.locale}})
      }else{
        this.$toasted.error(this.$t('loginFirst'))
      }
    },
    async getTotalAmount(){
      try{
        this.totalAmount = await this.defiContract.getTotalAmount()
      }catch(error){
        this.totalAmount = 0
        console.log('error', error)
      }
    },
    async getRoundAmount(){
      try{
        let round = await this.gameContract.getCurrRound()
        let detail = await this.gameContract.getRoundDetails(round)
        this.roundAmount = detail.roundamount
      }catch(error){
        this.roundAmount = 0
        console.log('error', error)
      }
    }
  },
  async mounted(){
    // console.log('==========index==========')
    this.gameContract = await new Game()
    this.defiContract = await new Defi()
    await this.getTotalAmount()
    await this.getRoundAmount()
  }
}
</script>
<style lang="scss">
.index{
  .bsc-egt-block{
    width: 100%;
    border: 2px solid #0abbb5;
    border-radius: 10px;
  }
}
</style>