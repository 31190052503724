import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import app from '../main'
import Home from '../views/Home.vue'
import Defi from '@/plugins/defi.js';
const cookies = require('vue-cookies')
Vue.use(VueRouter)
Vue.use(cookies);

const routes = [
  {
    path: '/:lang',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: false },
  },
  // EGT 註冊、購買、兌換
  {
    path: '/:lang/egt',
    name: 'Egt',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Egt/index.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/:lang/egt/registry',
    name: 'Egt-registry',
    component: () => import('../views/Egt/registry.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/:lang/egt/sell',
    name: 'Egt-sell',
    component: () => import('../views/Egt/sell.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/:lang/egt/swap',
    name: 'Egt-swap',
    component: () => import('../views/Egt/swap.vue'),
    meta: { requiresAuth: true },
  },

  // BSC-EGT 發送頁面
  {
    path: '/:lang/bscegt/send',
    name: 'Bscegt-send',
    component: () => import('../views/Bscegt/send.vue'),
    meta: { requiresAuth: true },
  },

  // EGT 換算器
  {
    path: '/:lang/calculator',
    name: 'Calculator',
    component: () => import('../views/Calculator/index.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/:lang/calculator/twd',
    name: 'Calculator-twd',
    component: () => import('../views/Calculator/twd.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/:lang/calculator/usdt',
    name: 'Calculator-usdt',
    component: () => import('../views/Calculator/usdt.vue'),
    meta: { requiresAuth: false },
  },

  // 註冊
  {
    path: '/:lang/registry',
    name: 'Defi-registry',
    component: () => import('../views/Defi/registry.vue'),
    meta: { requiresAuth: false },
  },

  // 我要投資
  {
    path: '/:lang/deposit',
    name: 'Defi-deposit',
    component: () => import('../views/Defi/deposit/index.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/:lang/deposit/list',
    name: 'Defi-deposit-list',
    component: () => import('../views/Defi/deposit/list.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/:lang/deposit/orders',
    name: 'Defi-deposit-orders',
    component: () => import('../views/Defi/deposit/orders.vue'),
    meta: { requiresAuth: true },
  },

  // 我要貸款
  {
    path: '/:lang/borrow',
    name: 'Defi-borrow',
    component: () => import('../views/Defi/borrow/index.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/:lang/borrow/create',
    name: 'Defi-borrow-create',
    component: () => import('../views/Defi/borrow/create/index.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/:lang/borrow/create/egt',
    name: 'Defi-borrow-create-egt',
    component: () => import('../views/Defi/borrow/create/egt.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/:lang/borrow/create/eth',
    name: 'Defi-borrow-create-eth',
    component: () => import('../views/Defi/borrow/create/eth.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/:lang/borrow/create/:token',
    name: 'Defi-borrow-create-token',
    component: () => import('../views/Defi/borrow/create/_token.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/:lang/borrow/loans',
    name: 'Defi-borrow-loans',
    component: () => import('../views/Defi/borrow/loans.vue'),
    meta: { requiresAuth: true },
  },

  // community
  {
    path: '/:lang/community',
    name: 'Community',
    component: () => import('../views/Community.vue'),
    meta: { requiresAuth: true },
  },

  // game
  {
    path: '/:lang/game',
    name: 'Game',
    component: () => import('../views/Game.vue'),
    meta: { requiresAuth: true },
  },

  // ADT
  {
    path: '/:lang/adt',
    name: 'ADT',
    component: () => import('../views/Adt.vue'),
    meta: { requiresAuth: true },
  },

  // Gas Fee
  {
    path: '/:lang/gas',
    name: 'GasFee',
    component: () => import('../views/GasFee.vue'),
    meta: { requiresAuth: false },
  },
  // i18n
  {
    path: '/:lang/i18n',
    name: 'i18n',
    component: () => import('../views/I18n.vue'),
    meta: { requiresAuth: false },
  },

  {
    // will match everything
    path: '*',
    component: () => import('../views/Other.vue'),
    meta: { requiresAuth: false },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  if (!to.params.lang || !store.state.langs.includes(to.params.lang)){
    next('/tw')
  }

  // Get contract address
  try{
    if (store.state.tokenList.length === 0){
      let defiContract = await new Defi()
      let tokenList = await defiContract.getToken()
      store.commit('updateTokenList', tokenList)
      // get contract address
      let address = await defiContract.getAddress()
      if (address === 'error'){
        console.log('error')
      }else{
        store.commit('updateAddress', address)
      }
    }
  }catch(error){
    console.log('error', error)
  }

  // Get user info from cookies
  try{
    let address = Vue.$cookies.get('address')
    if (address != undefined) {
      if ( store.state.account === '' ){
        try{
          store.commit('updateAccount', address)
        }catch(error){
          console.log('error', error)
        }
      }
    }
  }catch(error){
    console.log('error', error)
    next();
  }

  // 如果 router 轉跳的頁面需要驗證 requiresAuth: true
  if (to.matched.some(record => {
    // console.log(record.name, record.meta.requiresAuth);
    return record.meta.requiresAuth;
  })) {
    // 未登入
    if (store.state.account === ''){
      try{
        next({ name: 'Home' });
      }catch(error){
        console.log('error', error)
      }
    }else{
      next(); // 往下繼續執行
    }
  } else {
    next(); // 往下繼續執行
  }
});

router.afterEach(async (to) => {
  // Get lang from route path
  if (to.params.lang){
    store.commit('updateLang', to.params.lang)
    app.$i18n.locale = to.params.lang
  }
});


export default router
